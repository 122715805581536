<template>
    <div class="device-map">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =============================================== -->
        <b-container fluid class="base-color">
            <h2 class="d-inline">{{ dbDevices.name }}</h2>
            <l-map ref="map" :style="mapCSS()" :zoom="zoom" :center="[center.lat, center.lng]">
                <l-tile-layer :url="url"></l-tile-layer>
                <l-marker v-for="(p, idx) in markers" :key="idx" :lat-lng="[p.lat, p.lng]"></l-marker>
            </l-map>
            <common-footer :device_id="device_id" :links="['live', 'playback', 'chart', 'map', 'setting']"></common-footer>
        </b-container>
        <!-- =========================== -->
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
import CommonFooter from "@/components/CommonFooter";

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
    name: "DeviceMap",
    components: { CommonFooter, LMap, LTileLayer, LMarker },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: 0,
            dbDevices: [],
            //url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            center: {
                lat: 36.8444607407,
                lng: 138.273925
            },
            zoom: 5,
            markers: [
                //{ lat: 34.2692895, lng: 136.5897455, name: "真珠ようしょく" },
                //{ lat: 34.2825895, lng: 136.776867, name: "定置あみ" }
            ],
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        if (!this.jwt) this.$router.push({ name: "Login" });
        if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
        //---------------------------------
        this.device_id = this.$route.params.device_id;
        //---------------------------------
        this.isLoading = true;
        await this.getDevices();
        this.markerRegist();
        this.isLoading = false;
    },
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        //====================================================
        mapCSS: function() {
            let h = this.windowHeight;
            h = h - 200;
            if (h < 500) h = 500;
            return "height: " + h + "px;";
        },
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { id: this.device_id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevices = response.data.json[0];
            });
        },
        //====================================================
        async markerRegist() {
            let gps = this.dbDevices.gps;
            let device_id = this.dbDevices.id;
            let device_name = this.dbDevices.name;
            if (gps) {
                let latlon = gps.split(",");
                let lat = Number(latlon[0]);
                let lng = Number(latlon[1]);
                this.markers.push({ lat: lat, lng: lng, device_id: device_id, device_name: device_name });
                this.center.lat = lat;
                this.center.lng = lng;
                await new Promise(r => setTimeout(r, 1000));
                this.zoom = 8;
                this.center.lat = lat;
                this.center.lng = lng;
                await new Promise(r => setTimeout(r, 1000));
                this.zoom = 10;
                this.center.lat = lat;
                this.center.lng = lng;
                await new Promise(r => setTimeout(r, 1000));
                this.zoom = 13;
                this.center.lat = lat;
                this.center.lng = lng;
            }
        }
        //====================================================
    }
    //========================================================
};
</script>
