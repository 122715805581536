<template>
    <div class="CommonHeader">
        <!-- <b-navbar toggleable type="dark" variant="dark" fixed="top"> -->
        <b-navbar toggleable type="dark" variant="dark">
            <b-button v-b-toggle.sidebar-1 class="p-0 mr-3" variant="outline-secondary">
                <b-icon icon="justify" class="mx-3"></b-icon>
            </b-button>
            <router-link to="/" class="text-white">
                <b-navbar-brand class="font-weight-bold">ホカクンLite</b-navbar-brand>
            </router-link>
        </b-navbar>
        <b-sidebar id="sidebar-1" title="ホカクンJr" backdrop shadow width="180px" bg-variant="dark" text-variant="light">
            <div class="p-1">
                <router-link to="/devices-list">
                    <b-button block variant="success">
                        <span class="text-white font-weight-bold">リスト</span>
                    </b-button>
                </router-link>
            </div>
            <div class="p-1">
                <router-link to="/setting">
                    <b-button block variant="success">
                        <span class="text-white font-weight-bold">通知設定</span>
                    </b-button>
                </router-link>
            </div>
            <div class="p-1">
                <router-link to="/logout">
                    <b-button block variant="danger">
                        <span class="text-white font-weight-bold"> Logout </span>
                    </b-button>
                </router-link>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
export default {
    props: {
        //deviceId: Number
        //device_id: Number
    },
    data() {
        return {
            jwt: "",
            myInfo: []
            //device_id: 100
        };
    },
    mounted() {},
    watch: {},
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
    }
};
</script>
