import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";

import moment from "vue-moment";
import axios from "axios";
import VueLocalStorage from "vue-localstorage";
import VueAxios from "vue-axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueLocalStorage);
Vue.use(moment);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "/" : "https://jr.hokacloud.com";
//axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "/" : "http://www16325ui.sakura.ne.jp";

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
