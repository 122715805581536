<template>
    <div class="live-stream">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =============================================== -->
        <b-container fluid class="base-color">
            <b-row>
                <b-col md="12">
                    <div ref="cameraBox" class="mt-1">
                        <b-aspect v-show="!showFullscreen" :aspect="aspect" class="img">
                            <b-img :src="jpgImg" fluid-grow></b-img>
                        </b-aspect>
                        <!-- ######################################################################### -->
                        <div id="fullscreen" v-if="showFullscreen">
                            <b-img :src="jpgImg" fluid></b-img>
                            <!--
                            <b-button variant="primary" @click="showFullscreen = false" style="position:fixed;top:20px;right:20px;">Close</b-button>
                            -->
                        </div>
                        <!-- ######################################################################### -->
                        <b-row>
                            <b-col>
                                <b-progress :value="rpiLifetime" max="180" variant="success" striped animated show-value height="30px"></b-progress>
                                <div class="m-0" style="background-color: white;">
                                    <b-row>
                                        <b-col cols="5" align-self="center">
                                            <h6 class="m-0 p-1 text-truncate">{{ deviceName }}</h6>
                                        </b-col>
                                        <b-col cols="7">
                                            <b-button :disabled="!(mqttClient.connected && rpiLifetime > 1)" variant="success" class="m-1" style="width: 100px;" @click="publishLifetime()">延長</b-button>
                                            <b-button v-show="allowCapture()" :disabled="!(mqttClient.connected && rpiLifetime > 1)" variant="danger" class="m-1" style="width: 100px;" @click="publishCapture()">捕獲！</b-button>
                                            <!--
                                            <a href="#" @click.prevent="showFullscreen = !showFullscreen">
                                                <b-button variant="primary" class="mx-1 px-2">Full</b-button>
                                            </a>
                                            -->
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <div v-show="showFullscreen" class="my-3">&nbsp;</div>
                    </div>
                </b-col>
                <!-- =============================================== -->
                <b-col md="12" v-show="!showFullscreen">
                    <div ref="chatBox" class="mt-2">
                        <!-- --------------------------------- -->
                        <b-navbar type="dark" variant="info" class="m-0">
                            <b-form-input ref="talkInput" placeholder="チャット(発言は12時間で削除されます)" v-model="myTalk" @focus="viewFooter = false" @blur="viewFooter = true" @keydown.enter="submitTalk()"></b-form-input>
                            <b-input-group-append v-show="false">
                                <b-button @click="submitTalk()"><b-icon icon="chat-text-fill"></b-icon></b-button>
                            </b-input-group-append>
                        </b-navbar>
                        <!-- --------------------------------- -->
                        <b-alert fade :show="dismissCountDown" @dismissed="dismissCountDown = 0" class="m-0">
                            <h6 class="text-left">{{ newChatTalk }}</h6>
                            <h6 class="text-right">
                                <small>{{ newChatSender }}</small>
                            </h6>
                        </b-alert>
                        <!-- --------------------------------- -->
                        <div class="line-bc" :style="'height:' + chatBoxHeight + 'px;'">
                            <!-- https://saruwakakun.com/html-css/reference/speech-bubble -->
                            <div v-for="chat of dbChats" :key="chat.id">
                                <div v-show="chat.user_id != myInfo.id">
                                    <div class="balloon">
                                        <div class="chatting">
                                            <b-avatar class="align-top" :text="chat.user_name.charAt(0)"></b-avatar>
                                            <div class="says">
                                                <p>{{ chat.talk }}</p>
                                            </div>
                                            <small class="align-bottom mx-1" style="font-size: 11px;">{{ $moment(chat.talk_time).format("HH:mm") }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="chat.user_id == myInfo.id">
                                    <div class="mycomment">
                                        <small class="align-bottom mx-1" style="font-size: 11px;">{{ $moment(chat.talk_time).format("HH:mm") }}</small>
                                        <p>{{ chat.talk }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- --------------------------------- -->
                    </div>
                </b-col>
            </b-row>
            <!-- =============================================== -->
            <!-- ---------------------------------
            <b-navbar fixed="bottom" type="dark" variant="info" style="margin-bottom: 56px;">
                <b-form-input placeholder="チャット(発言は12時間で削除されます)" v-model="myTalk"></b-form-input>
                <b-input-group-append>
                    <b-button @click="submitTalk()"><b-icon icon="chat-text-fill"></b-icon></b-button>
                </b-input-group-append>
            </b-navbar>
            --------------------------------- -->
            <common-footer v-show="viewFooter && !showFullscreen" :device_id="device_id" :links="['live', 'playback', 'chart', 'map', 'setting']"></common-footer>
        </b-container>
        <!-- =============================================== -->
    </div>
</template>

<style scoped>
#fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
}
#fullscreen img {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    background-color: lightgray;
}
.img {
    background-color: lightgray;
    /* height: 60vh; */
    /* width: auto; */
    /* object-fit: contain; */
    object-fit: contain;
}
/* ------------- */
.line-bc {
    overflow: scroll;
    padding: 20px 10px;
    /*
    max-width: 450px;
    margin: 15px auto;
    */
    text-align: right;
    font-size: 14px;
    background: #7da4cd;
}
/* ------------- */
.balloon {
    width: 100%;
    margin: 10px 0;
    overflow: hidden;
}
.balloon .chatting {
    width: 100%;
    text-align: left;
}
/* ------------- */
.says {
    display: inline-block;
    position: relative;
    margin: 0 0 0 10px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: #edf1ee;
}
.says:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: -19px;
    border: 8px solid transparent;
    border-right: 18px solid #edf1ee;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
}
.says p {
    margin: 0;
    padding: 0;
    word-wrap: break-word;
}

/*以下、③右側の緑コメント*/
.mycomment {
    margin: 10px 0;
}
.mycomment p {
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 8px;
    max-width: 250px;
    border-radius: 12px;
    background: #30e852;
    font-size: 15px;
    word-wrap: break-word;
}
.mycomment p:after {
    content: "";
    position: absolute;
    top: 3px;
    right: -19px;
    border: 8px solid transparent;
    border-left: 18px solid #30e852;
    -webkit-transform: rotate(-35deg);
    transform: rotate(-35deg);
}
/* ------------- */
p-BK {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 0.8rem;
}
</style>

<script>
import JWT from "jsonwebtoken";
import CommonFooter from "@/components/CommonFooter";
import mqtt from "mqtt";
export default {
    name: "LiveStream",
    components: { CommonFooter },
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: 0,
            dbUser: {},
            dbDevice: {},
            dbChats: [],
            jpgImg: "", //"http://133.125.59.26/sample.jpg",
            myTalk: "",
            aspect: "16:9",
            mqttClient: { connected: false },
            mqttTopic: "rpi/xx:xx:xx:xx:xx:xx/lifetime",
            mqttMsg: '{"lifetime": 0}',
            rpiLifetime: 0,
            newChatSender: "",
            newChatTalk: "",
            dismissCountDown: 0,
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            chatBoxHeight: 200,
            viewFooter: true,
            showFullscreen: false, // フルスクリーンの表示切り替え
            isLoading: false
        };
    },
    //===========================================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        this.device_id = this.$route.params.device_id;
        this.isLoading = true;
        await this.getUser();
        await this.getDevices();
        await this.getChats();
        await this.initMqtt(this.dbDevice.mac);
        this.isLoading = false;
    },
    //===========================================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        window.addEventListener("orientationchange", this.handleOrientationChange);
        this.handleOrientationChange();
    },
    //===========================================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("orientationchange", this.handleOrientationChange);
        this.mqttClient.end();
    },
    //===========================================================================
    watch: {
        $route: async function(to, from) {
            if (from.fullPath !== to.fullPath) {
                this.device_id = to.params.device_id;
                this.getDevices();
            }
        }
    },
    //===========================================================================
    computed: {
        deviceName: function() {
            return this.dbDevice.name;
        }
    },
    //===========================================================================
    methods: {
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            //-----------------------------------
            let retHeight = 100;
            const dom = this.$refs.cameraBox;
            const rect = dom.getBoundingClientRect();
            retHeight = this.windowHeight - rect.height - 190;
            retHeight = Math.floor(retHeight);
            if (retHeight < 200) retHeight = 200;
            this.chatBoxHeight = retHeight;
            //-----------------------------------
        },
        //====================================================
        handleOrientationChange: function() {
            let direction = Math.abs(window.orientation);
            if (direction == 90) {
                this.showFullscreen = true;
            } else {
                this.showFullscreen = false;
            }
        },
        //====================================================
        publishLifetime() {
            let topic = "rpi/" + this.dbDevice.mac;
            let message = JSON.stringify({ lifetime: 180 });
            if (this.mqttClient.connected) this.mqttClient.publish(topic, message);
        },
        //====================================================
        publishCapture() {
            let ret = confirm("捕獲を実行しますか？");
            if (ret) {
                let topic = "rpi/" + this.dbDevice.mac;
                let message = JSON.stringify({ capture: 1 });
                if (this.mqttClient.connected) this.mqttClient.publish(topic, message);
            }
        },
        //====================================================
        publishChat() {
            let topic = "browser/" + this.dbDevice.mac;
            let message = JSON.stringify({
                chat: {
                    sender_id: this.myInfo.id,
                    sender_name: this.myInfo.name,
                    sender_talk: this.myTalk
                }
            });
            if (this.mqttClient.connected) this.mqttClient.publish(topic, message);
        },
        //====================================================
        async initMqtt(mac) {
            let myThis = this;
            //-----------------
            this.mqttClient = mqtt.connect("wss://jr.hokacloud.com/mqtt/", {
                username: "ise",
                password: "987654321a"
            });
            //-----------------
            this.mqttClient.on("connect", function() {
                self.console.log("MQTT Connected!!", mac);
            });
            //-----------------
            this.mqttClient.on("message", function(topic, message) {
                myThis.mqttTopic = topic;
                myThis.mqttMsg = message.toString();
                let msgJson = JSON.parse(myThis.mqttMsg);
                if (msgJson.lifetime) myThis.rpiLifetime = Number(msgJson.lifetime);
                if (msgJson.shot) myThis.jpgImg = msgJson.shot;
                if (msgJson.chat) {
                    if (msgJson.chat.sender_id != myThis.myInfo.id) {
                        myThis.newChatSender = msgJson.chat.sender_name;
                        myThis.newChatTalk = msgJson.chat.sender_talk;
                        myThis.dismissCountDown = 3;
                        //self.console.log(msgJson);
                    }
                    myThis.getChats();
                }
            });
            //-----------------
            this.mqttClient.subscribe("browser/" + mac);
        },
        //====================================================
        async getUser() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                //params: { id: this.device_id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                }
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbDevice = response.data.json[0];
                }
            });
        },
        //====================================================
        allowCapture() {
            if (!this.dbUser.capture_ids) return false;
            //---------------------------
            let capture_ids = JSON.parse(this.dbUser.capture_ids);
            return capture_ids.includes(this.dbDevice.id);
        },
        //====================================================
        async getChats() {
            await this.axios({
                method: "GET",
                url: "/web/api/chats/",
                params: {
                    device_id: this.device_id,
                    "talk_time[GREAT]": this.$moment()
                        .add(-12, "hours")
                        .format("YYYY-MM-DD HH:mm"),
                    "_order[talk_time]": "desc"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbChats = response.data.json;
                //--------------------------
                // メモの"\n"で改行
                for (let i in this.dbChats) {
                    let talk = this.dbChats[i].talk;
                    this.dbChats[i].talk = talk.replace(/\\n/g, "\n");
                }
                //--------------------------
            });
        },
        //====================================================
        submitTalk() {
            this.viewFooter = true;
            this.$refs.talkInput.blur();
            //-----------------------------------
            if (this.myTalk.trim().length == 0) {
                return false;
            }
            //-----------------------------------
            const axiosData = {
                device_id: this.device_id,
                user_id: this.myInfo.id,
                user_name: this.myInfo.name,
                talk_time: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
                talk: this.myTalk
            };
            //-----------------------------------
            this.axios({
                method: "POST",
                url: "/web/api/chats",
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 30000
            }).then(() => {
                this.publishChat();
                this.myTalk = "";
            });
        }
        //====================================================
    }
};
/*
CREATE TABLE `chats` (
  `id` int(10) unsigned NOT NULL AUTO_INCREMENT,
  `device_id` int(10) unsigned NOT NULL DEFAULT 0,
  `user_id` int(10) unsigned NOT NULL DEFAULT 0,
  `user_name` varchar(80) NOT NULL DEFAULT '',
  `talk_time` datetime NOT NULL,
  `talk` text DEFAULT NULL,
  `modified` datetime DEFAULT NULL,
  PRIMARY KEY (`id`),
  KEY `talk_time` (`talk_time`)
);
 */
</script>
