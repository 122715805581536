<template>
    <div class="setting">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =============================================== -->
        <b-container fluid class="base-color">
            <b-row>
                <b-col>
                    <h1 class="mt-3">設定</h1>
                    <b-card header="LINEプッシュ通知">
                        <div v-show="isLineUser()">
                            <b-button variant="success" :href="lineLink()">Lineアプリへ</b-button>
                        </div>
                        <!--
                        <div v-show="!isLineUser()">
                            <a href="https://lin.ee/7Uxcatd"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"/></a>
                        </div>
                        -->
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
export default {
    name: "Setting",
    components: {},
    //==========================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbUser: { id: 0, name: "", site_id: 0 },
            dbDevices: [],
            isLoading: false
        };
    },
    //==========================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.isLoading = true;
        await this.getUsers();
        await this.getDevices();
        this.isLoading = false;
    },
    //==========================================
    mounted: function() {},
    //==========================================
    methods: {
        //-----------------------------------------
        isLineUser() {
            if (this.dbUser.line_link) return true;
            else return false;
        },
        //-----------------------------------------
        lineLink() {
            return this.dbUser.line_link;
        },
        //-----------------------------------------
        async linePush() {
            await this.axios({
                method: "POST",
                url: "/web/api/line-push",
                data: { user_id: this.dbUser.id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            });
            alert("Lineプッシュ通知を送信しました。");
        },
        //-----------------------------------------
        async getUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbUser = response.data.json[0];
            });
        },
        //-----------------------------------------
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevices = response.data.json;
            });
        }
        //-----------------------------------------
    }
    //==========================================
};
</script>
