<template>
    <p>logout</p>
</template>

<script>
export default {
    name: "Logout",
    //==================================================
    created: function() {
        this.logout();
    },
    //==================================================
    methods: {
        logout: function() {
            this.$localStorage.remove("jwt");
            this.$router.push({ path: "/login" });
        }
    }
    //==================================================
};
</script>
