<script>
import { Line, mixins } from "vue-chartjs";

export default {
    extends: [Line],
    mixins: [Line, mixins.reactiveData],
    props: {
        //chartOptions: {},
        chartDataChild: {}
    },
    data() {
        return {
            chartOptions: {
                maintainAspectRatio: false,
                legend: { display: true, position: "bottom" },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            distribution: "linear", // use 'linear'(default) or 'series'
                            time: { displayFormats: { hour: "M月D日 H時", day: "M月D日 H時", month: "M月D日 H時" } },
                            ticks: { minRotation: 10, maxRotation: 40, maxTicksLimit: 9 },
                            gridLines: { drawOnChartArea: true }
                        }
                    ],
                    yAxes: [
                        {
                            display: false,
                            id: "axis-day-night",
                            ticks: { min: 0, max: 100 }
                        },
                        {
                            display: true,
                            id: "axis-volt",
                            position: "left",
                            ticks: { suggestedMin: 11, suggestedMax: 13, fontColor: "orange" },
                            scaleLabel: { display: true, labelString: "電圧", fontColor: "orange" }
                        },
                        {
                            display: false,
                            id: "axis-temp",
                            position: "left",
                            ticks: { suggestedMin: 0, suggestedMax: 50, fontColor: "green" },
                            scaleLabel: { display: true, labelString: "温度(℃)", fontColor: "green" }
                        },
                        {
                            display: false,
                            id: "axis-boot",
                            position: "left",
                            ticks: { suggestedMin: 0, suggestedMax: 1, fontColor: "green" },
                            scaleLabel: { display: true, labelString: "センサ検知", fontColor: "green" }
                        }
                    ]
                },
                tooltips: {
                    enabled: true
                },
                elements: {
                    line: { tension: 0.4 }, // line: { tension: 0.4 },
                    point: { radius: 1, hitRadius: 10, hoverRadius: 1, hoverBorderWidth: 3 }
                },
                animation: false
            }
        };
    },
    mounted() {
        this.renderChart(this.chartDataChild, this.chartOptions);
    },
    watch: {
        chartDataChild: function() {
            this.renderChart(this.chartDataChild, this.chartOptions);
        }
    }
};
</script>
