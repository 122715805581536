<template>
    <div class="devices-list">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =============================================== -->
        <b-container fluid class="base-color">
            <div v-for="device in dbDevices" :key="device.mac">
                <!-- ==================================================================== -->
                <router-link :to="{ name: 'LiveStream', params: { device_id: device.id } }">
                    <b-button block class="m-1 p-3" style="background-color: chocolate;">{{ device.name }}</b-button>
                </router-link>
                <!-- ==================================================================== -->
                <!--
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="p-0">
                        <b-button v-b-toggle="device.mac" block class="p-3" style="background-color: chocolate;">{{ device.name }}</b-button>
                    </b-card-header>
                    <b-collapse :id="device.mac" accordion="my-accordion">
                        <b-card-body>
                            <router-link :to="{ name: 'LiveStream', params: { device_id: device.id } }">
                                <b-button size="sm" variant="primary" class="m-1" style="width: 80px;">
                                    <b-icon icon="camera-reels"></b-icon>
                                    <div>Live</div>
                                </b-button>
                            </router-link>
                            <router-link :to="{ name: 'Playback', params: { device_id: device.id } }">
                                <b-button size="sm" variant="success" class="m-1" style="width: 80px;">
                                    <b-icon icon="caret-right-square"></b-icon>
                                    <div>Playback</div>
                                </b-button>
                            </router-link>
                            <router-link :to="{ name: 'DeviceChart', params: { device_id: device.id } }">
                                <b-button size="sm" variant="info" class="m-1" style="width: 80px;">
                                    <b-icon icon="graph-up"></b-icon>
                                    <div>Chart</div>
                                </b-button>
                            </router-link>
                            <router-link :to="{ name: 'DeviceMap', params: { device_id: device.id } }">
                                <b-button size="sm" variant="warning" class="m-1" style="width: 80px;">
                                    <b-icon icon="geo"></b-icon>
                                    <div>Map</div>
                                </b-button>
                            </router-link>
                            <router-link :to="{ name: 'DeviceSetting', params: { device_id: device.id } }">
                                <b-button size="sm" variant="danger" class="m-1" style="width: 80px;">
                                    <b-icon icon="gear"></b-icon>
                                    <div>Setting</div>
                                </b-button>
                            </router-link>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                -->
                <!-- ==================================================================== -->
            </div>
            <div class="mt-5"><span>&nbsp;</span></div>
        </b-container>
        <!-- =============================================== -->
        <b-navbar toggleable type="dark" variant="dark" fixed="bottom">
            <div class="text-white float-left"></div>
            <div class="text-white float-right">{{ myInfo.name }}</div>
        </b-navbar>
    </div>
</template>

<style>
table.b-table > thead > tr > th,
table.b-table > tbody > tr > td {
    white-space: nowrap;
    vertical-align: middle;
    padding: 3px;
}
.btn-blue {
    background-color: blue !important;
}
.btn-skyblue {
    background-color: royalblue !important;
}
.device-color {
    background-color: chocolate !important;
}
.home-bk {
    height: 200vh;
}
.footer-bk {
    height: 300px;
}
</style>

<script>
import JWT from "jsonwebtoken";
// @ is an alias to /src
export default {
    name: "DevicesList",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbDevices: [],
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            aspect: "16:9",
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.isLoading = true;
        await this.getDevices();
        this.isLoading = false;
    },
    //========================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        chkAlive(alived_at) {
            if (!alived_at) return false;
            let now = this.$moment().add(-5, "minutes");
            let alive = this.$moment(alived_at);
            if (now < alive) return false;
            return true;
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevices = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbDevices) {
                    let alived_at = this.dbDevices[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbDevices[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
            });
        }
        //====================================================
    }
};
</script>
