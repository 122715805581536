<template>
    <div class="CommonFooter">
        <!-- <b-navbar toggleable type="dark" variant="dark" fixed="bottom"> -->
        <b-navbar toggleable type="dark" variant="dark" fixed="bottom">
            <router-link :to="{ name: 'LiveStream', params: { device_id: device_id } }" v-show="links.includes('live')">
                <b-button size="sm" variant="primary" class="p-0" style="width: 70px;">
                    <small>
                        <b-icon icon="camera-reels"></b-icon>
                        <div>ライブ</div>
                    </small>
                </b-button>
            </router-link>
            <router-link :to="{ name: 'Playback', params: { device_id: device_id } }" v-show="links.includes('playback')">
                <b-button size="sm" variant="success" class="p-0" style="width: 70px;">
                    <small>
                        <b-icon icon="caret-right-square"></b-icon>
                        <div>録画</div>
                    </small>
                </b-button>
            </router-link>
            <router-link :to="{ name: 'DeviceChart', params: { device_id: device_id } }" v-show="links.includes('chart')">
                <b-button size="sm" variant="danger" class="p-0" style="width: 70px;">
                    <small>
                        <b-icon icon="graph-down"></b-icon>
                        <div>バッテリ</div>
                    </small>
                </b-button>
            </router-link>
            <router-link :to="{ name: 'DeviceMap', params: { device_id: device_id } }" v-show="links.includes('map')">
                <b-button size="sm" variant="warning" class="p-0" style="width: 70px;">
                    <small>
                        <b-icon icon="geo"></b-icon>
                        <div>地図</div>
                    </small>
                </b-button>
            </router-link>
        </b-navbar>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
export default {
    props: {
        device_id: Number,
        links: Array
    },
    data() {
        return {
            jwt: "",
            myInfo: []
            //device_id: 100
        };
    },
    mounted() {},
    watch: {},
    methods: {},
    created: function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
    }
};
</script>
