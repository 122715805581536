<template>
    <div>
        <b-progress :max="timeGuageMax">
            <b-progress-bar v-for="(item, index) in timerChartData" :class="item.color" :value="1" :key="index" />
        </b-progress>
        <b-progress :max="timeGuageMax">
            <b-progress-bar v-for="n in 24" v-bind:key="n" :class="timeRuler(n)" :value="60">{{ n - 1 }}</b-progress-bar>
        </b-progress>
    </div>
</template>

<style>
.clear {
    background-color: #f0f3f5 !important;
}
.shot {
    background-color: #ff0000 !important;
}
.dark1 {
    background-color: #555555 !important;
}
.dark2 {
    background-color: #666666 !important;
}
</style>

<script>
//import moment from 'moment';
export default {
    name: "TimeChart",
    props: ["imageList"],
    data() {
        return {
            frameSize: 5, //1分、5分、10分、15分、30分、60分
            timeGuageMax: 24 * (60 / 5), // 1コマ ＝ 5分
            timerChartData: []
        };
    },
    watch: {
        imageList: async function(newImageList) {
            await new Promise(r => setTimeout(r, 50));
            this.makeTimeChartData(newImageList);
        }
    },
    methods: {
        timeRuler(hour) {
            return hour % 2 == 0 ? "dark1" : "dark2";
        },
        makeTimeChartData(newImageList) {
            this.timerChartData = new Array(this.timeGuageMax).fill({ color: "clear" });
            for (let i in newImageList) {
                let frameNo = Math.floor(i / (60 * this.frameSize));
                if (newImageList[i] != "") this.timerChartData[frameNo] = { color: "shot" };
            }
        }
    }
};
</script>
