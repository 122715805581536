<template>
    <div class="login">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =============================================== -->
        <b-container fluid class="base-color">
            <div class="m-5"></div>
            <h1 class="text-light font-weight-bold">ホカクンLite</h1>
            <b-img :src="LogoImg" width="200"></b-img>
            <div class="m-3"></div>
            <b-form>
                <b-input-group class="mb-3">
                    <b-form-input type="text" v-model="username" placeholder="アカウント" />
                </b-input-group>
                <b-input-group class="mb-3">
                    <b-form-input type="password" v-model="password" placeholder="パスワード" />
                </b-input-group>
                <b-button block variant="primary" @click="jwtauth()">ログイン</b-button>
            </b-form>
            <h6 class="text-white text-right mt-3">Ver {{ ver }}</h6>
        </b-container>
        <!-- =============================================== -->
    </div>
</template>

<style>
.outer-div {
    display: table;
    width: 100vw;
    height: 100vh;
    background: #f69300;
}
.inner-div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 50px;
    padding-bottom: 200px;
}
</style>

<script>
import Version from "@/version.json";
export default {
    name: "Login",
    //==========================================
    data() {
        return {
            ver: Version.version,
            dbVersion: [],
            LogoImg: require("@/assets/logo.png"),
            username: "",
            password: "",
            lineId: "",
            liveJampDeviceId: 0,
            dbUsers: [],
            isLoading: false
        };
    },
    //==========================================
    created: async function() {
        if (this.$route.query.line) this.lineId = this.$route.query.line;
        this.username = this.$localStorage.get("username");
        this.password = this.$localStorage.get("password");
        if (this.$route.query.lj) this.liveJampDeviceId = this.$route.query.lj;
        this.isLoading = true;
        await new Promise(r => setTimeout(r, 1000));
        this.getVersion();
        this.isLoading = false;
    },
    //==========================================
    mounted: function() {},
    //==========================================
    updated: function() {},
    //==========================================
    methods: {
        //-----------------------------------------
        getVersion() {
            this.axios({
                method: "GET",
                url: "/web/versions",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 3000
            }).then(response => {
                this.isLoading = false;
                this.dbVersion = response.data.json[0];
                if (this.dbVersion.pro_version) {
                    if (this.dbVersion.pro_version > this.ver) {
                        if (confirm("新しいバージョンがあります。\n更新しますか？")) {
                            this.reloadApp();
                        }
                    }
                }
            });
        },
        //-----------------------------------------
        // バージョンアップ（サービスワーカーを解除し、リロードで再登録する）
        async reloadApp() {
            window.navigator.serviceWorker.getRegistrations().then(registrations => {
                for (let registration of registrations) registration.unregister();
            });
            await new Promise(r => setTimeout(r, 1000));
            window.location.reload();
            await new Promise(r => setTimeout(r, 1000));
            alert("更新しました。\n再起動して下さい。");
        },
        //-----------------------------------------
        jwtauth() {
            this.axios({
                method: "POST",
                url: "/web/api/authenticate",
                data: {
                    username: this.username.trim(),
                    password: this.password.trim(),
                    line_id: this.lineId.trim()
                },
                timeout: 3000 // 3000ms
            })
                .then(response => {
                    let jwt = response.data.token;
                    this.dbUsers = response.data.json;
                    if (jwt) {
                        this.$localStorage.set("jwt", jwt);
                        this.$localStorage.set("username", this.username.trim());
                        this.$localStorage.set("password", this.password.trim());
                        if (this.liveJampDeviceId) this.$router.push({ path: "/live-stream/" + this.liveJampDeviceId });
                        else this.$router.push({ path: "/" });
                    } else {
                        alert("登録がありません。");
                    }
                })
                .catch(error => {
                    alert("認証エラー");
                    self.console.log(error);
                });
        }
        //-----------------------------------------
    }
    //==========================================
};
</script>
