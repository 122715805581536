<template>
    <div class="playback">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- ######################################################################### -->
        <b-container fluid class="base-color">
            <b-row>
                <!-- =============================================== -->
                <b-col md="12">
                    <div ref="cameraBox" class="mt-1">
                        <!-- ------------------------- -->
                        <b-aspect v-show="!showFullscreen" :aspect="aspect" class="img">
                            <b-img :src="jpgImg" fluid-grow></b-img>
                        </b-aspect>
                        <!-- ------------------------- -->
                        <!-- ######################################################################### -->
                        <div id="fullscreen" v-if="showFullscreen">
                            <b-img :src="jpgImg" fluid></b-img>
                            <!--
                            <b-button variant="primary" @click="showFullscreen = false" style="position:fixed;top:20px;right:20px;">Close</b-button>
                            -->
                        </div>
                        <!-- ######################################################################### -->
                        <div style="background-color: white">
                            <b-row v-show="!showFullscreen">
                                <b-col cols="5">
                                    <h6 class="m-0 p-1 text-truncate">{{ dbDevice.name }}</h6>
                                </b-col>
                                <b-col cols="7">
                                    <h6 class="m-0 p-1 text-truncate">{{ $moment(targetDate).format("YY/M/D") }} {{ dispCursorTime() }}</h6>
                                </b-col>
                            </b-row>
                            <b-row v-show="!showFullscreen">
                                <b-col>
                                    <b-form-input type="range" min="0" :max="86400 - 1" v-model="cursor_time" @change="getImage()" style="margin-top: 0px; margin-bottom: -10px"></b-form-input>
                                    <timeline :imageList="imageList"></timeline>
                                </b-col>
                            </b-row>
                            <b-button-group class="my-2">
                                <b-form-datepicker
                                    v-show="!showFullscreen"
                                    button-variant="warning"
                                    button-only
                                    class="mx-1"
                                    v-model="targetDate"
                                    @input="getImageList()"
                                    :max="maxDate"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                ></b-form-datepicker>
                                <b-button variant="info" class="mx-1 px-2" @click="slideChange(-60)"><b-icon :width="showFullscreen ? '70' : 'auto'" icon="skip-backward-fill"></b-icon></b-button>
                                <b-button variant="info" class="mx-1 px-2" @click="slideChange(-1)"><b-icon :width="showFullscreen ? '70' : 'auto'" icon="skip-start-fill"></b-icon></b-button>
                                <b-button :variant="isPlay ? 'danger' : 'info'" class="mx-1 px-2" @click="slidePlay()"><b-icon :width="showFullscreen ? '70' : 'auto'" icon="play-fill"></b-icon></b-button>
                                <b-button variant="info" class="mx-1 px-2" @click="slideChange(1)"><b-icon :width="showFullscreen ? '70' : 'auto'" icon="skip-end-fill"></b-icon></b-button>
                                <b-button variant="info" class="mx-1 px-2" @click="slideChange(60)"><b-icon :width="showFullscreen ? '70' : 'auto'" icon="skip-forward-fill"></b-icon></b-button>
                                <b-button variant="danger" class="mx-1 px-2" @click="openModal()" v-show="!showFullscreen"><b-icon icon="chat-dots-fill"></b-icon></b-button>
                                <!--
                                <a href="#" @click.prevent="showFullscreen = !showFullscreen">
                                    <b-button variant="primary" class="mx-1 px-2">Full</b-button>
                                </a>
                                -->
                            </b-button-group>
                            <div v-show="showFullscreen" class="my-3">&nbsp;</div>
                        </div>
                        <!-- ------------------------- -->
                    </div>
                </b-col>
                <!-- =============================================== -->
                <b-col md="12">
                    <div v-show="!showFullscreen" ref="videoTagBox" class="mt-2">
                        <!-- https://saruwakakun.com/html-css/reference/speech-bubble -->
                        <div class="line-bc overflow-auto text-left" :style="'height:' + videoTagBoxHeight + 'px;'">
                            <div class="balloon">
                                <div v-for="tag of dbVideoTags" :key="tag.id" class="chatting">
                                    <b-avatar class="align-top ml-2 mt-2" icon="tags-fill" variant="info" size="sm"></b-avatar>
                                    <!-- <div class="says my-2"> -->
                                    <div :class="saysClass(tag.video_time)">
                                        <p @click="aaa(tag.video_time)">{{ tag.memo }}</p>
                                    </div>
                                    <small class="align-bottom" style="font-size: 11px;">{{ $moment(tag.video_time).format("HH:mm:ss") }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <!-- =============================================== -->
            </b-row>
            <common-footer v-show="!showFullscreen" :device_id="device_id" :links="['live', 'playback', 'chart', 'map', 'setting']"></common-footer>
        </b-container>
        <b-modal ref="modal-video-tag" title="録画タグ" centered no-close-on-backdrop no-close-on-esc header-bg-variant="primary" header-text-variant="light" @ok="submitModal">
            <b-form-textarea size="sm" v-model="modalData.memo"></b-form-textarea>
            <h6 class="text-danger"><small>削除の場合は、記入をクリアしOKボタンを押下してください。</small></h6>
        </b-modal>
    </div>
</template>

<style scoped>
#fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
}
#fullscreen img {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.img {
    background-color: lightgray;
    /* height: 60vh; */
    /* width: auto; */
    /* object-fit: contain; */
    object-fit: contain;
}
/* ------------- */
.line-bc {
    overflow: scroll;
    padding: 20px 10px;
    /*
    max-width: 450px;
    margin: 15px auto;
    */
    text-align: right;
    font-size: 14px;
    background: #7da4cd;
}
/* ------------- */
.balloon {
    width: 100%;
    margin: 10px 0;
    overflow: hidden;
}
.balloon .chatting {
    width: 100%;
    text-align: left;
}
/* ------------- */
.says {
    display: inline-block;
    position: relative;
    margin: 0 0 0 10px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: #30e852;
}
.says:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: -19px;
    border: 8px solid transparent;
    border-right: 18px solid #30e852;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
}
/* ------------- */
.highlight-says {
    display: inline-block;
    position: relative;
    margin: 0 0 0 10px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: pink;
}
.highlight-says:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: -19px;
    border: 8px solid transparent;
    border-right: 18px solid pink;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
}
/* ------------- */
p {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 0.8rem;
}
</style>

<script>
import JWT from "jsonwebtoken";
import CommonFooter from "@/components/CommonFooter";
import Timeline from "@/components/CompTimeline";
export default {
    name: "Playback",
    components: { Timeline, CommonFooter },
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: undefined,
            dbDevice: { id: 0, name: "", mac: "" },
            dbVideoTags: [],
            cursor_time: 0,
            jpgImg: "",
            imageProps: { blank: true },
            imageList: [],
            jpgSec: 1000,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            modalData: {
                id: 0,
                device_id: 0,
                user_id: 0,
                video_date: 0,
                video_time: 0,
                memo: ""
            },
            isPlay: false,
            aspect: "16:9",
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            videoTagBoxHeight: 200,
            isLoading: false,
            showFullscreen: false, // フルスクリーンの表示切り替え
            postResponse: []
        };
    },
    //===========================================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        this.device_id = this.$route.params.device_id;
        await this.getDevices();
        await this.getImageList();
    },
    //===========================================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        window.addEventListener("orientationchange", this.handleOrientationChange);
        this.handleOrientationChange();
    },
    //===========================================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("orientationchange", this.handleOrientationChange);
    },
    //===========================================================================
    watch: {
        $route: async function(to, from) {
            if (from.fullPath !== to.fullPath) {
                this.device_id = to.params.device_id;
                this.getDevices();
                await this.getImageList();
            }
        }
    },
    //===========================================================================
    computed: {},
    //===========================================================================
    methods: {
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            //-----------------------------------
            let retHeight = 100;
            const dom = this.$refs.cameraBox;
            const rect = dom.getBoundingClientRect();
            retHeight = this.windowHeight - rect.height - 140;
            retHeight = Math.floor(retHeight);
            if (retHeight < 100) retHeight = 100;
            this.videoTagBoxHeight = retHeight;
            //-----------------------------------
        },
        //====================================================
        handleOrientationChange: function() {
            let direction = Math.abs(window.orientation);
            if (direction == 90) {
                this.showFullscreen = true;
            } else {
                this.showFullscreen = false;
            }
        },
        //====================================================
        aaa(video_time) {
            const hh = this.$moment(video_time).hours();
            const mm = this.$moment(video_time).minute();
            const ss = this.$moment(video_time).seconds();
            this.cursor_time = hh * 3600 + mm * 60 + ss;
            this.getImage();
        },
        saysClass(video_time) {
            let hhmmss = this.$moment(video_time).format("HH:mm:ss");
            if (hhmmss == this.dispCursorTime()) return "highlight-says my-2";
            return "says my-2";
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevice = response.data.json[0];
            });
        },
        //====================================================
        slidePlay: async function() {
            if (this.isPlay == true) {
                this.isPlay = false;
            } else {
                this.isPlay = true;
                while (this.isPlay) {
                    await this.slideChange(+1);
                    await new Promise(r => setTimeout(r, 100));
                    if (this.cursor_time > 86400 - 100) this.isPlay = false;
                }
            }
        },
        //====================================================
        slideChange: async function(seconds) {
            let nextCursor = Number(this.cursor_time) + Number(seconds);
            while (0 < nextCursor && nextCursor < 86400) {
                if (this.imageList[nextCursor]) break;
                if (seconds == 0) break;
                if (seconds > 0) nextCursor++;
                else nextCursor--;
            }
            if (nextCursor < 0) {
                return true;
            }
            if (nextCursor > 86400 - 1) {
                return true;
            }
            this.cursor_time = nextCursor;
            this.getImage();
            //await new Promise(r => setTimeout(r, 5000));
        },
        //====================================================
        dispCursorTime: function() {
            const hh = (this.cursor_time / 3600) | 0; // 小数点切り捨て
            const mm = ((this.cursor_time - hh * 3600) / 60) | 0; // 小数点切り捨て
            const ss = this.cursor_time % 60; // 余り
            return ("00" + hh).slice(-2) + ":" + ("00" + mm).slice(-2) + ":" + ("00" + ss).slice(-2);
        },
        //====================================================
        async getImageList() {
            this.jpgImg = "";
            this.isLoading = true;
            await new Promise(r => setTimeout(r, 1000));
            await this.axios({
                method: "GET",
                url: "/web/api/rapid-image-list/" + this.device_id + "/" + this.targetDate,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.imageList = new Array(86400).fill("");
                    let dateAry = response.data.json;
                    for (let i in dateAry) {
                        let hh = this.$moment(dateAry[i]).hours();
                        let mm = this.$moment(dateAry[i]).minute();
                        let ss = this.$moment(dateAry[i]).second();
                        let aryNo = hh * 3600 + mm * 60 + ss;
                        this.imageList[aryNo] = dateAry[i];
                    }
                    this.isLoading = false;
                    this.isPlay = false;
                    this.cursor_time = 0;
                    this.slideChange(1);
                })
                .catch(err => {
                    this.isLoading = false;
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
            await this.getVideoTags();
        },
        //====================================================
        async getVideoTags() {
            await this.axios({
                method: "GET",
                url: "/web/api/video_tags/",
                params: {
                    device_id: this.device_id,
                    "video_time[GREAT]": this.$moment(this.targetDate).format("YYYY-MM-DD 00:00:00"),
                    "video_time[SMALL]": this.$moment(this.targetDate).format("YYYY-MM-DD 23:59:59"),
                    "_order[video_time]": "asc"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbVideoTags = response.data.json;
                //--------------------------
                // メモの"\n"で改行
                for (let i in this.dbVideoTags) {
                    let memo = this.dbVideoTags[i].memo;
                    this.dbVideoTags[i].memo = memo.replace(/\\n/g, "\n");
                }
                //--------------------------
            });
        },
        //====================================================
        getImage() {
            const jpgKey = this.imageList[this.cursor_time];
            if (!jpgKey) {
                this.jpgImg = "";
                this.imageProps = { blank: true, width: 16, height: 9 };
                return false;
            } else {
                this.imageProps = { blank: false };
            }
            this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/rapid-image/" + this.device_id + "/" + jpgKey,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.jpgImg = window.URL.createObjectURL(response.data);
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        openModal() {
            this.modalData.id = 0;
            this.modalData.device_id = this.device_id;
            this.modalData.user_id = this.myInfo.id;
            this.modalData.user_name = this.myInfo.name;
            this.modalData.video_date = this.targetDate;
            this.modalData.video_time = this.dispCursorTime();
            this.modalData.memo = "";
            for (let i in this.dbVideoTags) {
                let tmp_time = this.dbVideoTags[i].video_time;
                if (this.$moment(tmp_time).unix() == this.$moment(this.modalData.video_date + " " + this.modalData.video_time).unix()) {
                    this.modalData.id = this.dbVideoTags[i].id;
                    this.modalData.memo = this.dbVideoTags[i].memo;
                }
            }
            this.$refs["modal-video-tag"].show(); // Modal Show
        },
        //====================================================
        async submitModal() {
            if (this.modalData.memo.trim().length == 0 && this.modalData.id == 0) {
                return false;
            }
            //-----------------------------------
            const axiosData = {
                device_id: this.modalData.device_id,
                user_id: this.modalData.user_id,
                user_name: this.modalData.user_name,
                video_time: this.modalData.video_date + " " + this.modalData.video_time,
                memo: this.modalData.memo
            };
            //-----------------------------------
            let restMethod = "PUT";
            if (this.modalData.id == 0) restMethod = "POST";
            if (this.modalData.memo.trim().length == 0 && this.modalData.id > 0) restMethod = "DELETE";

            let restUrl = "/web/api/video_tags";
            if (this.modalData.id > 0) restUrl = restUrl + "/" + this.modalData.id;

            await this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 30000
            }).then(() => {
                this.getVideoTags();
            });
            alert("保存しました。");
        }
        //====================================================
    }
};
/*
CREATE TABLE video_tags (
  id int(10) unsigned NOT NULL AUTO_INCREMENT,
  device_id int(10) unsigned NOT NULL DEFAULT 0,
  user_id int(10) unsigned NOT NULL DEFAULT 0,
  video_time datetime NOT NULL,
  memo text DEFAULT NULL,
  modified datetime DEFAULT NULL,
  PRIMARY KEY (`id`),
  KEY video_time (video_time)
);


+-------------------------+
| Tables_in_sea_dragon_db |
+-------------------------+
| chart_configs           |
| last_sensings           | rest-rpi.js
| sensor_summaries        |
| sites_users             |
+-------------------------+

drop table chart_configs;
drop table last_sensings;
drop table sensor_summaries;
drop table sites_users ;



 */
</script>
