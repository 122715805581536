<template>
    <div class="device-chart">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- ###################################################### -->
        <b-container fluid class="base-color">
            <b-row>
                <b-col cols="8">
                    <b-form-datepicker v-model="targetDate" @input="chartReload()" :max="maxDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-datepicker>
                </b-col>
                <b-col cols="4">
                    <b-form-select v-model="daySpanValue" :options="daySpanOptions" @change="chartReload()"></b-form-select>
                </b-col>
                <b-col>
                    <b-card class="mt-3" border-variant="secondary" bg-variant="secondary" no-body>
                        <div class="bg-white">
                            <h4 class="pt-3">{{ dispDate }}</h4>
                            <comp-device-chart :chartDataChild="chartDeviceDataSet" class="chart" />
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <common-footer :device_id="device_id" :links="['live', 'playback', 'chart', 'map', 'setting']"></common-footer>
        </b-container>
        <!-- ###################################################### -->
    </div>
</template>

<style>
.chart {
    /* height: 60vh; */
    height: 50%;
    width: 95%;
}
</style>

<script>
import JWT from "jsonwebtoken";
import CommonFooter from "@/components/CommonFooter";
import CompDeviceChart from "@/components/CompDeviceChart";
export default {
    name: "DeviceChart",
    components: { CommonFooter, CompDeviceChart },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            targetDate: this.$moment().format("YYYY-MM-DD"), //targetDate: "2020-08-21",
            maxDate: this.$moment().format("YYYY-MM-DD"),
            device_id: undefined,

            daySpanValue: 7,
            daySpanOptions: [
                { text: "1日間", value: 1 },
                { text: "2日間", value: 2 },
                { text: "7日間", value: 7 },
                { text: "14日間", value: 14 },
                { text: "30日間", value: 30 }
            ],
            dbDevices: undefined,
            dbSensorLogs: undefined,
            dbData: {
                xyDaytime: [],
                xyBattery: [],
                xySolar: [],
                xyBoot: [],
                xyCpu: []
            },
            chartDeviceDataSet: {
                // datasets: []
            },
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        if (!this.jwt) this.$router.push({ name: "Login" });
        if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
        this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetDate) this.targetDate = this.$route.query.targetDate;

        this.isLoading = true;
        await this.getDevices();
        await this.getSensorData();
        this.isLoading = false;
    },
    //========================================================
    computed: {
        dispDate: function() {
            let ret = "";
            let sdays = (this.daySpanValue - 1) * -1;
            ret = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("MM/DD");
            ret += "〜";
            ret += this.$moment(this.targetDate).format("MM/DD");
            return ret;
        }
    },
    //========================================================
    methods: {
        deviceName() {
            let ret = "";
            for (let idx in this.dbDevices) {
                let id = this.dbDevices[idx].id;
                if (id == this.device_id) ret = this.dbDevices[idx].name;
            }
            return ret;
        },
        //====================================================
        async chartReload() {
            if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
            this.isLoading = true;
            await new Promise(r => setTimeout(r, 500));
            await this.getSensorData();
            this.isLoading = false;
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        async getSensorData() {
            //--------------------------------
            let sdays = (this.daySpanValue - 1) * -1;
            //--------------------------------
            const sdate = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate)
                .add(+1, "days")
                .format("YYYY-MM-DD");
            //--------------------------------
            let requestURL = "/web/api/sensor_logs";
            requestURL += "?device_id=" + this.device_id;
            requestURL += "&arrived_at[EQGREAT]=" + sdate;
            requestURL += "&arrived_at[SMALL]=" + edate;
            requestURL += "&_order[id]=asc";
            requestURL += "&_fields=sensor_key,sensor_val,arrived_at";
            await this.axios({
                method: "GET",
                url: requestURL,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbSensorLogs = response.data.json;
                this.registXYdata(sdate, edate);
                this.registDeviceChartData();
            });
        },
        //====================================================
        registXYdata(sdate, edate) {
            self.console.log(sdate, edate);
            const defaultPoint = [
                { x: sdate, y: null },
                { x: edate, y: null }
            ];
            this.dbData.xyDaytime = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyBattery = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySolar = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyCpu = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyBoot = JSON.parse(JSON.stringify(defaultPoint));
            for (let i in this.dbSensorLogs) {
                let x = this.dbSensorLogs[i].arrived_at;
                x = this.$moment(x).format("YYYY-MM-DD HH:mm");
                let y = this.dbSensorLogs[i].sensor_val;
                let s = this.dbSensorLogs[i].sensor_key;
                if (s == "battery") this.dbData.xyBattery.push({ x: x, y: Number(y) });
                if (s == "solar") this.dbData.xySolar.push({ x: x, y: Number(y) });
                if (s == "cpu") this.dbData.xyCpu.push({ x: x, y: Number(y) });
                if (s == "boot") this.dbData.xyBoot.push({ x: x, y: Number(y) });
            }
            //--------------------------------
            let dt = "";
            for (let i = 0; i < 30; i++) {
                dt = this.$moment(sdate)
                    .add(i, "day")
                    .format("YYYY-MM-DD");
                if (dt >= edate) break;
                //------------------------------------------------------------
                this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: -100 });
                this.dbData.xyDaytime.push({ x: dt + " 06:00:00", y: 0 });
                this.dbData.xyDaytime.push({ x: dt + " 12:00:00", y: 100 });
                this.dbData.xyDaytime.push({ x: dt + " 18:00:00", y: 0 });
            }
            this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: -100 });
        },
        //====================================================
        async registDeviceChartData() {
            this.chartDeviceDataSet = {};
            let tmpDS2 = new Array();

            tmpDS2.push({
                label: "バッテリ電圧",
                yAxisID: "axis-volt",
                //data: this.dbData.xyBattery,
                data: JSON.parse(JSON.stringify(this.dbData.xyBattery)),
                borderColor: "red",
                borderWidth: 2,
                fill: false
            });
            tmpDS2.push({
                label: "センサ検知",
                yAxisID: "axis-boot",
                //data: this.dbData.xyBattery,
                data: JSON.parse(JSON.stringify(this.dbData.xyBoot)),
                borderColor: "green",
                borderWidth: 2,
                fill: false
            });
            // tmpDS2.push({
            //     label: "ソーラー電圧",
            //     yAxisID: "axis-volt",
            //     //data: this.dbData.xySolar,
            //     data: JSON.parse(JSON.stringify(this.dbData.xySolar)),
            //     borderColor: "orange",
            //     borderWidth: 2,
            //     fill: false
            // });
            // tmpDS2.push({
            //     label: "CPU温度",
            //     yAxisID: "axis-temp",
            //     //data: this.dbData.xyCpu,
            //     data: JSON.parse(JSON.stringify(this.dbData.xyCpu)),
            //     borderColor: "green",
            //     borderWidth: 2,
            //     fill: false
            // });
            tmpDS2.push({
                label: "昼夜",
                yAxisID: "axis-day-night",
                //data: this.dbData.xyDaytime,
                data: JSON.parse(JSON.stringify(this.dbData.xyDaytime)),
                borderColor: "gold",
                backgroundColor: "rgba(255, 255, 0, 0.05)",
                borderWidth: 1,
                lineTension: 0.5, //「日の出/日の入り」風
                fill: true
            });
            this.chartDeviceDataSet = JSON.parse(JSON.stringify({ datasets: tmpDS2 }));
        }
        //====================================================
    }
};
</script>
