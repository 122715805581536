<template>
    <div id="app">
        <common-header></common-header>
        <router-view></router-view>
    </div>
</template>

<style>
html {
    touch-action: manipulation;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.base-color {
    display: table;
    width: 100vw;
    height: 100vh;
    background: #f69300;
    padding-top: 58px;
    padding-bottom: 0px;
}
</style>

<script>
import CommonHeader from "@/components/CommonHeader";
// import CommonFooter from "@/components/CommonFooter";
export default {
    components: {
        // CommonFooter,
        CommonHeader
    }
};
</script>
