<template>
    <div class="device-setting">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =============================================== -->
        <b-container fluid class="base-color">
            <h2 class="d-inline">{{ dbDevice.name }}</h2>
            <b-card>
                <b-form>
                    <b-form-group label="ライフタイム" label-cols="5">
                        <b-form-select v-model="newConfig.lifeTime" :options="newConfig.lifeTimeOptions"></b-form-select>
                    </b-form-group>
                    <b-form-group label="解像度" label-cols="5">
                        <b-form-select v-model="newConfig.jpgSize" :options="newConfig.jpgSizeOptions"></b-form-select>
                    </b-form-group>
                    <b-form-group class="mt-5">
                        <b-button variant="primary" @click="onSubmit()">Submit</b-button>
                    </b-form-group>
                </b-form>
            </b-card>
            <common-footer :device_id="device_id" :links="['live', 'playback', 'chart', 'map']"></common-footer>
        </b-container>
        <!-- =========================== -->
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
import CommonFooter from "@/components/CommonFooter";
export default {
    name: "DevicesList",
    components: { CommonFooter },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: 0,
            dbDevice: { id: 0, name: "", mac: "" },
            dbRaspiConfig: { id: 0, mac: "", jpeg_url: "http://192.168.0.100/cgi-bin/viewer/video.jpg?resolution=1920x1080", life_time: 60 },
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            newConfig: {
                lifeTime: "60",
                lifeTimeOptions: [
                    { value: "60", text: "1分" },
                    { value: "120", text: "2分" },
                    { value: "180", text: "3分" },
                    { value: "240", text: "4分" },
                    { value: "300", text: "5分" }
                ],
                jpgSize: "640x360",
                jpgSizeOptions: [
                    { value: "640x360", text: "640x360" },
                    { value: "1024x576", text: "1024x576" }, //WSVGA
                    { value: "1280x720", text: "1280x720" }, //HD
                    { value: "1920x1080", text: "1920x1080" } //FHD
                ]
            },
            flashMessage: "",
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------
        this.device_id = this.$route.params.device_id;
        //---------------------------------
        this.isLoading = true;
        await this.getDevices();
        await this.getRaspiConfig();
        this.isLoading = false;
    },
    //========================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { id: this.device_id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevice = response.data.json[0];
            });
        },
        //====================================================
        async getRaspiConfig() {
            await this.axios({
                method: "GET",
                url: "/web/api/raspi_configs",
                params: { mac: this.dbDevice.mac },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbRaspiConfig = response.data.json[0];
                    this.newConfig.lifeTime = String(this.dbRaspiConfig.life_time);
                    let jpeg_url = this.dbRaspiConfig.jpeg_url;
                    let params = jpeg_url.split("=");
                    if (params[1]) this.newConfig.jpgSize = params[1];
                }
            });
        },
        //====================================================
        async onSubmit() {
            //-----------------------------------
            const axiosData = {
                mac: this.dbDevice.mac.trim(),
                jpeg_url: "http://192.168.0.100/cgi-bin/viewer/video.jpg?resolution=" + this.newConfig.jpgSize,
                life_time: Number(this.newConfig.lifeTime)
            };
            //-----------------------------------
            let restMethod = "PUT";
            if (this.dbRaspiConfig.id == 0) restMethod = "POST";
            let restUrl = "/web/api/raspi_configs";
            if (this.dbRaspiConfig.id > 0) restUrl = restUrl + "/" + this.dbRaspiConfig.id;
            //-----------------------------------
            await this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 3000
            }).then(response => {
                if (response.data.json[0]) {
                    this.flashMessage = "保存しました。";
                    alert("保存しました。");
                }
            });
        }
    }
};
</script>
