import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import NotFound from "../views/NotFound.vue";
//--------------------------------------------
import DevicesList from "../views/DevicesList.vue";
//--------------------------------------------
import DeviceChart from "../views/DeviceChart.vue";
import LiveStream from "../views/LiveStream.vue";
import Playback from "../views/Playback.vue";
import DeviceSetting from "../views/DeviceSetting.vue";
import DeviceMap from "../views/DeviceMap.vue";
//--------------------------------------------
import Setting from "@/views/Setting.vue";
//--------------------------------------------

Vue.use(VueRouter);

const routes = [
    {
        name: "Login",
        path: "/login",
        meta: { isPublic: true },
        component: Login
    },
    {
        name: "Logout",
        path: "/logout",
        meta: { isPublic: true },
        component: Logout
    },
    {
        name: "Home",
        path: "/",
        //component: Home
        component: DevicesList
    },
    {
        name: "NotFound",
        path: "*",
        component: NotFound
    },
    {
        name: "DevicesList",
        path: "/devices-list",
        component: DevicesList
    },
    {
        name: "LiveStream",
        path: "/live-stream/:device_id",
        component: LiveStream
    },
    {
        name: "Playback",
        path: "/playback/:device_id",
        component: Playback
    },
    {
        name: "DeviceChart",
        path: "/device-chart/:device_id",
        component: DeviceChart
    },
    {
        name: "DeviceMap",
        path: "/device-map/:device_id",
        component: DeviceMap
    },
    {
        name: "DeviceSetting",
        path: "/device-setting/:device_id",
        component: DeviceSetting
    },
    {
        path: "/setting",
        name: "Setting",
        component: Setting
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
    }
];

const router = new VueRouter({
    routes
});

export default router;
